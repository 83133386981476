<template>
  <b-field :label="`Site Url ${required ? '*' : ''}`">
    <b-field>
      <b-select v-model="field.protocol" :disabled="disabled">
        <option v-for="p in protocols" :key="p" :value="p">{{ p }}</option>
      </b-select>

      <b-input
        ref="url"
        v-model="field.fqdn"
        :disabled="disabled || locked"
        :readonly="locked"
        placeholder="yourdomain.com"
        expanded
      />
    </b-field>
  </b-field>
</template>

<script>
export default {
  name: "SiteUrlField",
  props: {
    url: {
      type: String,
      default: null,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      protocols: ["http://", "http://www.", "https://", "https://www."],
      field: {
        protocol: "https://",
        fqdn: ""
      }
    };
  },
  computed: {
    sanitised() {
      let field = this.field;
      let www = !field.fqdn.indexOf("www.") ? "www." : "";
      let protocol = field.fqdn.match(/^((http(s)?:\/\/)(www\.)?)/gi);
      protocol = protocol ? protocol[0] : field.protocol;
      protocol = `${protocol}${www}`.replace(/(www\.)+$/gi, "www.");
      let fqdn = field.fqdn.replace(/^((http(s)?:\/\/)?(www\.)*)/gi, "");
      fqdn = fqdn.replace(/[/?](.*)$/gi, "");
      return {
        protocol: protocol,
        fqdn: fqdn,
        url: protocol + fqdn
      };
    },
    isValidFdqn() {
      return this.$v.isFQDN(this.sanitised.fqdn);
    },
    isValidUrl() {
      return this.$v.isURL(this.sanitised.url, {
        protocols: ["http", "https"]
      });
    },
    isValid() {
      return this.isValidUrl && this.isValidFdqn;
    }
  },
  watch: {
    sanitised() {
      this.$emit("change", this.isValid ? this.sanitised : null);
      if (this.isValid) {
        this.field.protocol = this.sanitised.protocol;
        this.field.fqdn = this.sanitised.fqdn;
      }
    }
  },
  created() {
    if (this.url) {
      const regex = /^((http(s)?:\/\/)(www\.)?)?(.*)/gi;
      const matches = regex.exec(this.url);
      this.field.protocol = matches[1] || this.field.protocol;
      this.field.fqdn = matches[5] || this.field.fqdn;
    }
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.url.$el.querySelector("input").focus();
    }
  }
};
</script>
